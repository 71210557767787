// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {GoogleAuthProvider,signInWithPopup, getAuth} from'firebase/auth';
import { getAnalytics } from "firebase/analytics";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: "AIzaSyDqbJFaUZF7n1Y9gzoJpqk87mpdERocPy8",
//   authDomain: "fightright-1d3c0.firebaseapp.com",
//   projectId: "fightright-1d3c0",
//   storageBucket: "fightright-1d3c0.appspot.com",
//   messagingSenderId: "53336621935",
//   appId: "1:53336621935:web:01dd945b37c85b7f7fde9a",
//   measurementId: "G-8SBV7DRLYG"
// };

// ------------------FIGHT_RIGHT_ACCOUNT_-------
const firebaseConfig = {
    apiKey: "AIzaSyBzQr8ysgt1q_rT0GGcjRDxtnmWsMklq8A",
    authDomain: "fightright-d8bca.firebaseapp.com",
    projectId: "fightright-d8bca",
    storageBucket: "fightright-d8bca.appspot.com",
    messagingSenderId: "154740952712",
    appId: "1:154740952712:web:96de7f62a3587ec80a193d",
    measurementId: "G-1PBHX0SCLW"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


// ---------------------------------------------------
export const auth = getAuth(app)
export const provider = new GoogleAuthProvider() 

// export const signInWithGoogle = () =>{
//     signInWithPopup(auth,provider).then((result ) =>{
//         console.log(result);
//     })
//     .catch((error)=>{
//         console.log(error);
//     })
// };

// export const signInWithGoogle = () => {
//     signInWithPopup(auth, provider)
//         .then((result) => {
//             // Do something with the result.user, for example:
//             setPicture(result.user.photoURL);
//             localStorage.setItem('userData', JSON.stringify(result.user));
//             setShowPopup(false);
//             setPopupNew(false);
//             setUser(result.user);
//         })
//         .catch((error) => {
//             // Handle errors here
//             console.log(error);
//         });
// };

export const signInWithGoogle = () => {
    return new Promise((resolve, reject) => {
      signInWithPopup(auth, provider)
        .then((result) => {
          // Resolve the promise with the user data
          resolve(result.user);
        })
        .catch((error) => {
          // Reject the promise with the error
          reject(error);
        });
    });
  };

  
