import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import DisclaimerPage from "./disclaimer/DisclaimerPage.js";
import { GoogleLoginButton } from "react-social-login-buttons";
import { LoginSocialGoogle } from "reactjs-social-login";
import AWS from "aws-sdk";
import { signInWithGoogle } from "./Firebase";
import { signInWithPopup, onAuthStateChanged, signOut } from "firebase/auth";
import { auth, provider } from "./Firebase";
import axios from "axios";
import { FaRegEye, FaRegEyeSlash, FaLongArrowAltUp } from "react-icons/fa";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import ErrorBoundary from "./error/error_page";
import Feedback from "./Feedback";
import { HiOutlineLogout, HiOutlineEye, HiPlusCircle } from "react-icons/hi";
import { PiAddressBook, PiBookOpenText } from "react-icons/pi";
import { MdOutlinePrivacyTip, MdSend } from "react-icons/md";
import TypeWriter from "./TypeWriter";
import Crads from "./Cards";

function App() {
  // mobile version changes
  const apiUrl = process.env.REACT_APP_API_URL;
  const [chatLog, setChatLog] = useState([]);

  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDisclaimerAcknowledged, setIsDisclaimerAcknowledged] =
    useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupNew, SetpopupNew] = useState(true);
  const [newuser, newsetUser] = useState(null);
  const [handleresponse, sethandleresponse] = useState(true);
  const [picture, setPicture] = useState("");
  const [privacyPopup, setprivacyPopup] = useState(false);
  const [askquestion, setaskquestion] = useState(false);
  const [showEmailSignIn, setShowEmailSignIn] = useState(false);
  const [showEmailSignUp, setShowEmailSignUp] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState("");
  const [gmail, setGmail] = useState("");
  const [password, setPassword] = useState("");
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [fullName, setFullName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [showCreateAccountField, setShowCreateAccountField] = useState(false);
  const [fullNameError, setFullNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  //-------for Full Name Error Display
  const [nameError, setNameError] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [feedbackfrom, setFeedbackfrom] = useState(false);
  const [chatsteam, setchatsteam] = useState('');

  const inputRef = useRef(null);

  const showFeedback = () => {
    setFeedbackfrom(true);
  };

  const feebbackPopupClose = () => {
    setFeedbackfrom(false);
  };

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleEmailSignUp = () => {
    // toggle the showEmailForm state
    setShowEmailForm(true);
  };

  const handleEmailInput = async (e) => {
    try {
      const response = await axios.post(
        // "http://localhost:8000/api/user/getUserByEmail",
        "https://vfi6l4xzo2.execute-api.ap-south-1.amazonaws.com/getUserByEmail",
        {
          email: gmail,
        }
      );
      // console.log(response);
      // console.log(response.data);
      if (response.data.message === "Successfully fetched user data.") {
        // console.log(response.data.email);
        // If the user data is returned, it means the email is registered.
        // So, show the login form.
        // setShowLoginForm(true);
        // setShowForm(false);
        setShowPasswordField(true);
        // setShowEmailForm(false);
        setShowForm(false);
      } else {
        // If no user data is returned, it means the email is not registered.
        // So, show the sign-up form.
        // setShowForm(true);
        setShowCreateAccountField(true);
        // setShowEmailForm(false);
        // console.log("Error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // -------------------testing--------------------
  // const handleEmailInput = async (e) => {
  //   e.preventDefault();
  //   console.log(gmail);
  //   try {
  //     const response = await axios.post('http://localhost:8000/api/user/getUserByEmail', {
  //       email: gmail
  //     });
  //     console.log(response.data);
  //     if (response.data.message==="Successfully fetched user data.") {
  //       // If the user data is returned, it means the email is registered.
  //       // So, show the password input in the same form.
  //       setShowPasswordField(true);
  //     }
  //     else {
  //       // If no user data is returned, it means the email is not registered.
  //       // So, show the sign-up form.
  //       setShowForm(true);
  //       setShowEmailForm(false);
  //     }
  //   } catch (error) {
  //     console.error('An error occurred:', error);
  //   }
  // };

  const storedData = localStorage.getItem("userData");
  const parsedData = JSON.parse(storedData);

  const chatLogContainerRef = useRef(null);

  const [toggle, setToggle] = useState(true);
  const [styles, setStyles] = useState({ display: "none" });
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const handleButtonClickanswer = () => {
    setaskquestion(true);
  };

  const handleCloseanswer = () => {
    setaskquestion(false);
  };

  const handleButtonClick = () => {
    setprivacyPopup(true);
  };

  const handleClosePopupprivacy = () => {
    setprivacyPopup(false);
  };

  const handleEmailSignIn = () => {
    setShowEmailSignIn(true);
  };

  const handleCancel = () => {
    window.location.reload();
    setShowForm(false);
    setShowLoginForm(false);
    setShowEmailForm(false);
  };

  useEffect(() => {
    handleAddPlayer();


    // if (chatLogContainerRef) {
    //   chatLogContainerRef.current.addEventListener('DOMNodeInserted', event => {
    //     const { currentTarget: target } = event;
    //     target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
    //   });
    // }
    const storedData = localStorage.getItem("userData");
    const parsedData = JSON.parse(storedData);
    // console.log(chatsteam);
    // setTimeout(() => {
    //   if (chatLogContainerRef.current) {
    //     chatLogContainerRef.current.scrollTo({
    //       top: chatLogContainerRef.current.scrollHeight,
    //       behavior: "smooth",
    //     });
    //   }
    // }, 0);
    if (parsedData) {
      if (parsedData.photoURL) {
        setPicture(parsedData.photoURL);
      } else {
        setPicture(null);
      }
      // console.log(picture)
      SetpopupNew(false);
      setIsDisclaimerAcknowledged(true);
    } else {
      //setPicture(process.env.PUBLIC_URL + "user.png");
      SetpopupNew(true);
      newsetUser(null);
    }
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    if (chatLogContainerRef.current) {
      chatLogContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end"
      });
    }
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };

  }, [chatLog]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {

      e.preventDefault();
      handleSubmit(e);
    }

  };
  const handleFullNameChange = (e) => {
    const value = e.target.value;
    setFullName(value);
    // if (!value) {
    //   setNameError("Please enter full name");
    // } else {
    //   setNameError("");
    // }

  };

  const handleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);

      const user = result.user;
      localStorage.setItem("userData", JSON.stringify(user));
      localStorage.setItem("displayName", user.displayName);
      setPicture(user.photoURL);
      newsetUser(user);
      setShowPopup(false);
      SetpopupNew(false);

    } catch (error) {
      // Handle the error
      console.log(error);
    }
  };

  const saveToS3 = async (file, filename) => {
    const now = new Date();

    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_PUBLIC_AWS_REGION,
    });

    let s3Params = {};

    if (parsedData.displayName) {
      s3Params = {
        Bucket: `fr-chatbot-response-cp`,
        Body: JSON.stringify(file),
        Key: `Response/${year}/${parsedData.displayName}/${parsedData.email}/${day}_${month}_${year}_${filename}.json`,
        ContentType: "application/json",
      };
    } else {
      s3Params = {
        Bucket: `fr-chatbot-response-cp`,
        Body: JSON.stringify(file),
        Key: `Response/${year}/${parsedData.email}/${parsedData.email}/${day}_${month}_${year}_${filename}.json`,
        ContentType: "application/json",
      };
    }
    try {
      // console.log(file)
      const response = await s3.upload(s3Params).promise();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async function (e) {
    //console.log(parsedData,loading);
    if (!parsedData || loading) {
      e.preventDefault();
      console.log(parsedData, loading);
      return;
    }
    e.preventDefault();
    sethandleresponse(false);
    setShowPopup(false);
    // Simulating an asynchronous API request
    setTimeout(async () => {
      await handleAddPlayer(input);
      setInput("");
      setShowPopup(false);
    }, 200);
  };

  const handleOnChange = function (e) {
    setInput(e.target.value);
    setShowPopup(true);
    //console.log("hi");
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem('displayName');
    setPicture("");
    SetpopupNew(true);
    newsetUser(null);
    window.location.reload();
  };

  const splitIntoParagraphs = (text) => {
    const stack = [];
    let currentIndex = 0;
    let start = 0;

    while (currentIndex < text.length) {
      if (text.substr(currentIndex, 2) === "\n\n") {
        const paragraph = text.substring(start, currentIndex).trim();
        if (paragraph.length > 0) {
          stack.push(paragraph);
        }
        start = currentIndex + 2;
        currentIndex = start;
      } else if (text[currentIndex] === "\n") {
        const line = text.substring(start, currentIndex).trim();
        if (line.length > 0) {
          stack.push(line);
        }
        start = currentIndex + 1;
        currentIndex = start;
      } else {
        currentIndex++;
      }
    }

    const lastParagraph = text.substring(start).trim();
    if (lastParagraph.length > 0) {
      stack.push(lastParagraph);
    }

    return stack;
  };

  const fetchData = async (endpoint, bodyprompt) => {
    try {
      setLoading(true);
      const response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify(bodyprompt),
      });

      const json = await response.json();

      const content = json.Response.choices[0].message.content;

      const paragraphs = splitIntoParagraphs(content);

      // const paragraphs = content.split("\n\n");
      // console.log(paragraphs)
      const newMessage = { role: "assistant", content: paragraphs };
      setChatLog((prevChatLog) => [...prevChatLog, newMessage]);
      setLoading(false);
      const currentTime = new Date().toLocaleTimeString("en-IN", {
        timeZone: "Asia/Kolkata",
      });
      const updatedJSON = {
        ...json,
        prompt: { user: parsedData.displayName, query: input },
      };
      const stringData = content;
      // console.log(stringData);
      setchatsteam(stringData);

      // json.prompt=
      const date = new Date();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      const formattedTime = `${hours}:${minutes}:${seconds}`;
      // console.log(parsedData)
      saveToS3(updatedJSON, formattedTime);
      // console.log(updatedJSON)
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleNewChat = () => {
    window.location.reload();
  };

  const isGreeting = (input) => {
    const greetings = ["hi", "hello", "hey", "greetings", "how are you"];
    const lowercasedInput = input.toLowerCase();
    const regex = new RegExp(`\\b(${greetings.join("|")})\\b`);

    return regex.test(lowercasedInput);
  };

  const handleAddPlayer = async (player) => {
    setInput(player);

    if (chatLog.length === 0 && handleresponse) {
      // Initial opening message from assistant
      const initialMessage = {
        role: "assistant",
        content:
          "Hi! I'm LawGeek, your AI-powered legal companion. How can I assist with your legal queries today?",
      };
      setChatLog((prevChatLog) => [...prevChatLog, initialMessage]);

    } else {
      if (player === undefined) {
      } else {
        // console.log(player)
        setChatLog((prevChatLog) => [
          ...prevChatLog,
          { role: "user", content: player },
        ]);
      }
    }

    if (player !== undefined) {
      const prompt_design = `Assume the role of a firebrand attorney knowledgeable in Indian law, and provide a comprehensive opinion on the following inquiry in square brackets : [${player}]. Your response should adhere to the following guidelines: If the question pertains to general law or involves a specific statute, act, section, or legal term, focus your answer on that specific question of law. If the question deals with a legal issue, offer guidance on how this matter could be resolved under Indian jurisdiction unless specified otherwise. Be sure to detail the relevant central and state acts and sections in full text. In case the question relates to a legal dispute, also: a. Suggest necessary steps, procedures, processes, and the involved courts or authorities. b. Discuss potential immediate reliefs such as injunctions, stays, or restraining orders. c. Propose a timeline for the process and discuss alternative legal remedies if any. Ensure your response is in bullet-point format or in a structured format. Answer in max 300 tiktokens and please do not reference yourself as a firebrand in the response`;
      let newarray;
      if (isGreeting(player)) {
        // console.log("greeting")
        newarray = {
          text: player,
          key: process.env.REACT_APP_API_KEY,
        };
      } else {
        // console.log(prompt_design)
        newarray = {
          text: prompt_design,
          key: process.env.REACT_APP_API_KEY,
        };
      }

      fetchData(apiUrl, newarray);
    }

    // saveToS3(parsedData,currentTime)
  };

  const handleAcknowledged = () => {
    setIsDisclaimerAcknowledged(true);
  };
  // console.log(isDisclaimerAcknowledged)

  if (!isDisclaimerAcknowledged && parsedData) {
    return <DisclaimerPage onAcknowledged={handleAcknowledged} />;
  }
  let active = 1;
  function myFunction() {
    // let x = document.getElementsByClassName("side-menu");
    active === 0 ? (active = 1) : active == 0;
    // console.log(active);
  }

  const activeClass =
    toggle && window.innerWidth < 1024
      ? "decactive-side-menu"
      : "active-side-menu";
  //console.log(window.innerWidth);
  // Firebase Registation

  // REGISTER-USER
  const registerUser = async (email, password) => {
    // if (!fullName) {
    // setNameError("Full name is required");
    // return false;
    // }
    try {
      // console.log("> Registering user");
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // console.log(user);
      setNameError(""); // clear error message if registration is successful
      setErrorMessage("");
      return true; // Registration was successful
    } catch (e) {
      if (e.code === "auth/weak-password") {
        setErrorMessage("Your password should be at least 6 characters.");
      } else {
        // you can add more cases for other error codes or leave a generic error message
        setErrorMessage("An error occurred during registration.");
      }
      return false; // Registration failed !
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log("onSubmit was called"); // <- Add this
    // console.log("fullName is", fullName); // <- And this

    if (!fullName) {
      setNameError("Full name is required");
      //return false;
    } else {
      setNameError("");
    }
    if (!password) {
      setErrorMessage("Password is required");
    } else {
      setErrorMessage("");
    }

    if (!fullName || !password || !gmail) {
      return;
    }

    const isUserRegistered = await registerUser(gmail, password);
    // console.log(isUserRegistered);
    if (isUserRegistered) {
      try {
        const { user } = await signInWithEmailAndPassword(
          auth,
          gmail,
          password
        );
        setIsAuthenticated(true);
        localStorage.setItem("userData", JSON.stringify(user));
        setPicture(user.photoURL);
        newsetUser(user);
        setShowForm(false);
        setShowLoginForm(false);
        setShowPopup(false);
        SetpopupNew(false);
      } catch (e) {
        console.log(e);
        // handle error here
      }
    }
  };

  // Firebase Login

  const loginUser = async (e) => {
    // e.preventDefault();
    try {
      const { user } = await signInWithEmailAndPassword(auth, gmail, password);
      // console.log("This is USSER",user);
      setIsAuthenticated(true); // Set the isAuthenticated state to true to show the chat page
      // console.log(user);
      localStorage.setItem("userData", JSON.stringify(user));
      setPicture(user.photoURL); // if you're using this for logged in user
      newsetUser(user); // if you're using this for logged in user
      setShowForm(false);
      setShowLoginForm(false);
      setShowPopup(false);
      SetpopupNew(false);
    } catch (e) {
      console.log(e);
      if (e.code === "auth/wrong-password") {
        setErrorMessage("Incorrect Password Please Try Again");
      } else {
        // you can add more cases for other error codes or leave a generic error message
        setErrorMessage("An error occurred during registration.");
      }
      // handle error here, for example show an error message
    }
  };

  const onLogin = async (e) => {
    e.preventDefault();
    loginUser(email, password);
  };

  const onExample = (e, exampleitem) => {

    if (!parsedData) {
      setShowPopup(true);
      return;
    }
    setInput(exampleitem);



    // if (!parsedData || loading) {
    //   e.preventDefault();
    //   return;
    // }
    inputRef.current.focus();
    e.preventDefault();
    sethandleresponse(false);
    setShowPopup(false);
    // Simulating an asynchronous API request
    const response = setTimeout(async () => {
      await handleAddPlayer(exampleitem);
      setInput("");
      setShowPopup(false);
      //console.log("trigger");
    }, 200);
    //console.log("trigger");

  };

  const Example = ["I am a hindu male, what's the process for getting a divorce ?", "A popular restaurant served me non-veg food against my oder a veg-food",]
  const Example2 = ["My tenant is not paying rent and also not leaving/vacting ", "I lost my mobile what will be the next step?"]
  const capabilities = ["Understands various fields of indian law", "provides immedidate relevant advice based on your query", "provides immedidate relevant advice based on your query"];
  const limition = ["Not a substitute for a lawyer",
    "Provides preliminary guidance, but detailed legal advice should come from a professional", "Advice tailored to Indian law and may not apply to other jurisdictions"]
  return (
    <ErrorBoundary>
      <div className="App">
        <img
          src={
            toggle
              ? process.env.PUBLIC_URL + "menu-icon.png"
              : process.env.PUBLIC_URL + "close-icon.png"
          }
          alt="menu-icon"
          onClick={() => setToggle(!toggle)}
          className={"toogle-button"}
        />
        {toggle && window.innerWidth < 1024 ? (
          <div style={{ marginTop: "10px" }} className="side-menu-header">
            {/* <img src={process.env.PUBLIC_URL + ""} alt="Logo" className="logo-image" /> */}
            <img
              src={process.env.PUBLIC_URL + "BharatKanoon Logo.png"}
              alt="Logo"
              className="top-left-image"
            />
          </div>
        ) : (
          ""
        )}
        {/* {toggle && ( */}
        <aside
          className={`side-menu ${activeClass} ${showPopup && popupNew ? "blur-background" : ""
            }`}
        >
          <div className="side-menu-header">
            {/* <img src={process.env.PUBLIC_URL + ""} alt="Logo" className="logo-image" /> */}
            <img
              src={process.env.PUBLIC_URL + "BharatKanoon Logo.png"}
              alt="Logo"
              className="top-left-image"
            />
          </div>
          {/* <div className="side-menu-button" onClick={handleNewChat}>
          <span>+ </span> New Chat
        </div> */}

          <div className="gif-image-container">
            <a
              href="https://fightright.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <img
              src={process.env.PUBLIC_URL + "LawGeek (2).png"}
              alt="GIF"
              className="gif-image"
            /> */}
            </a>
          </div>
          <div className="side-menu-button" onClick={handleNewChat}>
            <HiPlusCircle className="fr-icons" /> <span> New Chat</span>
          </div>

          <div className="tabs-container">
            <div className="tab">
              <HiOutlineEye className="fr-icons" />
              <a
                href="https://fightright.in/about.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Vision and Misson</span>
              </a>
            </div>
            <div className="tab" onClick={handleButtonClick}>
              <MdOutlinePrivacyTip className="fr-icons" />
              <span>Privacy Policy & Terms of Usage</span>
            </div>
            <div className="tab" onClick={handleButtonClickanswer}>
              <PiBookOpenText className="fr-icons" />
              <span> How To Use</span>

            </div>
            <div className=" tab">
              <PiAddressBook className="fr-icons" />
              <a
                href="https://fightright.in/contact.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span> Contact Us</span>
              </a>
            </div>
            {parsedData && (

              <div className="tab" onClick={handleLogout}>
                <HiOutlineLogout className="fr-icons" />
                <span>Logout</span>
              </div>
            )}
          </div>

          <div className="user-logo">
            {picture ? (
              <div>
                <img
                  src={picture}
                  alt="User"
                  className="assist-image"
                // referrerpolicy="no-referrer"
                />
                <p>{localStorage.getItem('displayName')}</p>

              </div>

            ) : (
              null
              // <img
              //   src={`${process.env.PUBLIC_URL}/user.png`}
              //   alt="User"
              //   className="assist-image"
              // />
            )}
          </div>
        </aside>
        {/* )} */}

        <section className="chatbox">
          <div className="chat-log-container" >
            <div className="inner-header">
              <div className="remaining-credit"></div>

            </div>
            {/* <button className="feedback-button" onClick={showFee dback}>
              feedback
            </button> */}
            <div
              className={`chat-log ${showPopup && popupNew ? "blur-background" : ""
                }`}
            >
              {chatLog.map((message, index) => (
                <div
                  className={`chat-message ${message.role === "user" ? "user" : "assistant"
                    }`}
                  key={index}
                >
                  <div className="chat-message-center">
                    {message.role === "user" ? (
                      <div className="avatar user-avatar">
                        {picture ? (
                          <img
                            src={picture}
                            alt="User"
                            className="assist-image"
                            referrerPolicy="no-referrer"
                          />
                        ) : (
                          <img
                            src={`${process.env.PUBLIC_URL}/user.png`}
                            alt="User"
                            className="user-image"
                          />
                        )}
                      </div>
                    ) : (
                      <div className="avatar assistant-avatar">
                        <img
                          src={process.env.PUBLIC_URL + "female-assist.png"}
                          alt="Logo"
                          className="assist-image"
                        />
                      </div>
                    )}
                    <div className="message" ref={chatLogContainerRef} >

                      {Array.isArray(message.content) ? (
                        <div className="message-box" >

                          <TypeWriter contents={chatsteam} />

                          {/* {message.content.map((paragraph, pIndex) => (

                            <div key={pIndex} className="nested-paragraph">

                              {Array.isArray(paragraph) ? (

                                paragraph.map((nestedParagraph, npIndex) => (
                                  // <p
                                  //   key={npIndex}
                                  //   className="message-paragraph-nested"
                                  // >
                                  //   {nestedParagraph}
                                  // </p>

                                  //addToChatStream(nestedParagraph);
                                  <TypeWriter key={npIndex} className="message-paragraph-nested" content={chatsteam} speed={40} />
                                ))


                              ) : (
                                // <p className="message-paragraph">{paragraph}</p>
                                // <TextStreamingEffect text={paragraph}/>

                                <TypeWriter content={paragraph} speed={10} />
                              )}
                            </div>
                          ))} */}
                        </div>
                      ) : (
                        <p>{message.content}</p>
                      )}
                      {loading && index === chatLog.length - 1 && (
                        <div className="loading-wave">
                          <div className="wave"></div>
                          <div className="wave"></div>
                          <div className="wave"></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {privacyPopup && (
              <div className="disclaimer-container-popup">
                <div className="disclaimer-content-popup">
                  <button
                    className="disclaimer-close-button-popup"
                    onClick={handleClosePopupprivacy}
                  >
                    &times;
                  </button>

                  <h2 className="disc-text">
                    PRIVACY POLICY, TERMS OF USE, AND DISCLAIMER
                  </h2>
                  <p>
                    The following document outlines the Privacy Policy, Terms of
                    Use, and Disclaimer that governs your access to and use of
                    the website “BharatKanoon.AI”, application “LawGeek”
                    (collectively, the "Site"), which are provided by
                    BharatKanoon.AI and FIGHTRIGHT Technologies Private Limited
                    ("we", "us", or "our").
                  </p>
                  <p>1. PRIVACY POLICY AND TERMS OF USE</p>
                  <p>
                    By engaging with our Site and services, you express your
                    understanding and acceptance of the ways we collect, use,
                    and disclose your data as described below:
                  </p>
                  <p>
                    User Information: Your provision of Personal Data (including
                    but not limited to your email, name, country, etc.) through
                    our Site or services signifies your agreement to this
                    Privacy Policy and explicit consent to the processing of
                    your Personal Data in accordance with the terms herein.
                  </p>
                  <p>
                    Data Collection: We collect a variety of data, including:
                  </p>
                  <p>
                    Personal Data: Information such as your name, email address,
                    and country. While providing this information is not
                    mandatory, failure to do so may limit your access to some
                    features of the Site and prevent you from receiving
                    promotional communications from us.
                  </p>
                  <p>
                    Automated Information Collection: Our websites automatically
                    collect data like your IP address, browser type, time spent
                    on different pages, etc. If accessed through a mobile
                    device, we might also collect device-specific data. This
                    data is non-personalized and not linked to the Personal Data
                    you choose to share with us.
                  </p>
                  <p>
                    Contests, Giveaways, and Surveys Data: During events like
                    contests and giveaways, we may collect additional
                    information like your shipping address and full name.
                  </p>
                  <p>
                    Data Use and Disclosure: We primarily collect your data to
                    improve our services. We also use your data to enhance the
                    Site's efficiency and operation, monitor Site usage, and
                    respond to product and customer service requests. We may
                    disclose your data to third parties for business purposes
                    and may share it with third-party service providers, other
                    third parties for analytics, and our partners for marketing
                    purposes.
                  </p>
                  <p>
                    Tracking Technologies: Our Site employs cookies and other
                    tracking technologies to enhance user experience. Our use of
                    third-party analytics services, like Google Analytics,
                    allows us to better understand usage patterns. Please note,
                    however, that we are not responsible for the privacy
                    practices or content of any third-party websites linked
                    from/to our Site.
                  </p>
                  <p>2. DISCLAIMER</p>
                  <p>
                    Before using BharatKanoon.AI and LawGeek, please read this
                    disclaimer carefully:
                  </p>
                  <p>
                    General Information Only: BharatKanoon.AI and LawGeek
                    provide general information based on Indian law, not legal
                    advice.
                  </p>
                  <p>
                    Jurisdiction Limitation: Information and services from
                    BharatKanoon.AI and LawGeek pertain to Indian law only.{" "}
                  </p>
                  <p>
                    No Attorney-Client Relationship: Usage of BharatKanoon.AI
                    and LawGeek does not establish an attorney-client
                    relationship with us.
                  </p>
                  <p>
                    Accuracy, Completeness, and Timeliness: We strive to provide
                    accurate and timely information, but we do not guarantee the
                    reliability, accuracy, or completeness of the information or
                    services provided by BharatKanoon.AI and LawGeek.
                  </p>
                  <p>
                    Reliance on Information: We advise consulting with a legal
                    professional for advice tailored to your circumstances. Any
                    reliance on the information provided by BharatKanoon.AI and
                    LawGeek is strictly at your own risk.
                  </p>
                  <p>
                    Governing Law: This disclaimer, and any disputes related to
                    it or the use of BharatKanoon.AI and LawGeek, are governed
                    by the laws of India.
                  </p>
                  <p>
                    By accessing and using our Site and services, you
                    acknowledge that you have read, understood, and agreed to
                    the Privacy Policy, Terms of Use, and Disclaimer.
                  </p>
                  <p>
                    If you have any queries, please feel free to contact us:
                    contact@fightright.in
                  </p>
                </div>
              </div>
            )}

            {askquestion && (
              <div className="answer-container-popup">
                <div className="answer-content-popup">
                  <button
                    className="disclaimer-close-button-popup"
                    onClick={handleCloseanswer}
                  >
                    &times;
                  </button>
                  <p className="top-para">
                    <img
                      src={process.env.PUBLIC_URL + "question.png"}
                      className="question-image"
                    ></img>
                    <h4>How to Ask Your Question</h4>
                  </p>
                  <p className="para-ex">
                    Getting legal advice has never been easier. Our AI-powered
                    chatbot is equipped to guide you on a wide range of legal
                    fields with immediate responses tailored to Indian law. No
                    need for legal jargon or prior legal knowledge! You can
                    present your queries as questions or simply describe your
                    situation.
                  </p>
                  <p>
                    <h4>Here are some examples:</h4>
                  </p>
                  <div className="div-container">
                    <div className="div-ex">
                      <p className="para-new-ex">
                        1. <strong>Criminal Law:</strong> "What should I do if
                        I'm wrongfully arrested?" or "I've been wrongfully
                        arrested."
                      </p>
                      <p className="para-new-ex">
                        2. <strong>Family Law:</strong> "What's the process for
                        getting a divorce?" or "I'm considering getting a
                        divorce"
                      </p>
                    </div>
                    <div className="div-ex">
                      <p className="para-new-ex">
                        3. <strong>Consumer Protection::</strong> "What can I do
                        if I get a faulty product from an online store?" or "I
                        received a faulty product from an online store"
                      </p>
                      <p className="para-new-ex">
                        4. <strong>Property Law::</strong> "I want to evict my
                        tenant, what should I do?" or "I need to evict my
                        tenant"
                      </p>
                    </div>
                    <div className="div-ex">
                      <p className="para-new-ex">
                        5. <strong>Intellectual Property::</strong> "How can I
                        patent my invention?" or "I'm looking to patent my
                        invention."
                      </p>
                      <p className="para-new-ex">
                        6. <strong>Insurance Law:</strong> "My health insurance
                        claim got rejected for no reason. How can I challenge
                        this?" or "My health insurance claim was unjustly
                        rejected."
                      </p>
                    </div>
                  </div>
                  <p className="para-ex">
                    Remember, all you need to do is type in your question or
                    describe your situation related to any legal field, and let
                    our chatbot guide you. While our chatbot provides helpful
                    initial advice, for comprehensive legal counsel, it's always
                    best to consult with a legal professional.
                  </p>
                </div>
              </div>
            )}

            {handleresponse && (
              <div
                className={`example-para ${showPopup && popupNew ? "blur-background" : ""
                  }`}
              >
                <div className="example-image">
                  <div className="card">

                    <div className="container-cards">
                      {
                        Example.map((item, index) => (
                          <button type="button" key={index} className="example-button gradient-border" onClick={(e) => onExample(e, item)} > <div className="inside-btn"><span className="btn-text">{item}</span><span className="btn-icon"><MdSend /></span></div>   </button>
                        ))
                      }
                    </div>
                  </div>

                  <div className="card">
                    <div className="container-cards">
                      {
                        Example2.map((item, index) => (
                          <button type="button" key={index} className="example-button gradient-border" onClick={(e) => onExample(e, item)}><div className="inside-btn"><span className="btn-text">{item}</span><span className="btn-icon"><MdSend /></span></div>  </button>
                        ))
                      }

                    </div></div>


                </div>
              </div>
            )}
          </div>
          <div className="chatbox-input-area">
            <form id="chatForm" onSubmit={handleSubmit}>
              <div
                className={`input-container ${showPopup && popupNew ? "blur-background" : ""
                  }`}
              >
                <textarea
                  rows="1"
                  placeholder="Ask Your Question to LawGeek"
                  className="chatbox-input-textarea"
                  value={input}
                  onChange={handleOnChange}
                  onKeyDown={handleKeyDown}
                  ref={inputRef}
                ></textarea>
                {/* <button type="submit" className="send-button">
                  Send
                </button> */}
              </div>
              <p className="para-extab">
                This is an intiative of <span className="brand-logo">FIGHT                
                <span className="right-span">RIGHT</span></span> Technologies, which is
                into Litigation Funding, Analytics & services.{" "}
                <a href="https://fightright.in" target="_blank">
                  Click
                </a>{" "}
                Here to learn more.
              </p>
            </form>

            {feedbackfrom ? (
              <div className="popup-message">
                <div className="blur-background"></div>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      <b>Feedback</b>
                    </h5>
                    <button
                      className="close-button"
                      onClick={feebbackPopupClose}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="popup-content">
                      <Feedback />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {popupNew && showPopup && (
              <div className="popup-message">
                <div className="blur-background"></div>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      <b>Login/Signup</b>
                    </h5>
                    <button className="close-button" onClick={handlePopupClose}>
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="popup-content">
                      <b>
                        Welcome to Law
                        <span style={{ color: "#01B0F0" }}>Geek</span>!
                      </b>
                    </div>
                    {!showForm && !showLoginForm && !showEmailForm && (
                      <div className="login-container">
                        <div>
                          <button
                            className="google-login-button"
                            onClick={handleSignIn}
                          >
                            {/* <button className="google-login-button" > */}
                            <div className="google-icon-container">
                              <img
                                src={process.env.PUBLIC_URL + "google_icon.png"}
                                className="google-icon"
                              />
                            </div>
                            <span className="google-login-text">
                              Sign in with Google
                            </span>
                          </button>
                        </div>
                        <div>
                          <button
                            className="email-login-button"
                            onClick={handleEmailSignUp}
                          >
                            <img
                              className="firebaseui-idp-icon"
                              alt=""
                              src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg"
                            ></img>
                            <span className="email-login-text">
                              Sign in with Email
                            </span>
                          </button>
                        </div>
                      </div>
                    )}

                    {showEmailForm && (
                      <form
                        onSubmit={
                          showPasswordField
                            ? onLogin
                            : (e) => e.preventDefault()
                        }
                        className="reg-email"
                      >
                        <div className="reg-email-content">
                          <div>
                            <h3 className="Modal-title">Sign in with email</h3>
                            <div className="input-group">
                              <input
                                id="email"
                                name="email"
                                type="email"
                                value={gmail}
                                className="input"
                                onChange={(e) => setGmail(e.target.value)}
                              />
                              {emailError && (
                                <div className="error error-red">
                                  {emailError}
                                </div>
                              )}
                              <label
                                className={`placeholder ${gmail
                                  ? "placeholder-pos-aft-focus"
                                  : "placeholder-pos-normal"
                                  }`}
                              >
                                Email address
                              </label>
                            </div>

                            {showPasswordField && (
                              <div className="input-group">
                                <input
                                  id="password"
                                  name="password"
                                  type="password"
                                  required
                                  className={"input"}
                                  onChange={(e) => setPassword(e.target.value)}
                                />

                                {errorMessage && (
                                  <div className="error error-red">
                                    {errorMessage}
                                  </div>
                                )}
                                <label
                                  className={`placeholder ${password
                                    ? "placeholder-pos-aft-focus"
                                    : "placeholder-pos-normal"
                                    }`}
                                >
                                  Password
                                </label>
                              </div>
                            )}
                            {showCreateAccountField && (
                              <section>
                                <div>
                                  <div>
                                    <form onSubmit={onSubmit}>
                                      <div>
                                        <div className="input-group">
                                          <input
                                            type="text"
                                            label="Full name"
                                            value={fullName}
                                            onChange={(e) =>
                                              setFullName(e.target.value)
                                            }
                                            // onChange={handleFullNameChange}
                                            required
                                            className="input"
                                          />
                                          <label
                                            htmlFor="full-name"
                                            className={`placeholder ${fullName
                                              ? "placeholder-pos-aft-focus"
                                              : "placeholder-pos-normal"
                                              }`}
                                          >
                                            First & last name
                                          </label>
                                          {nameError && (
                                            <div className="error error-red">
                                              {nameError}
                                            </div>
                                          )}
                                        </div>

                                        <div className="input-group">
                                          <input
                                            id="Password"
                                            type={
                                              showPassword ? "text" : "password"
                                            }
                                            label="Create password"
                                            value={password}
                                            onChange={(e) =>
                                              setPassword(e.target.value)
                                            }
                                            required
                                            className="input"
                                          />
                                          {errorMessage && (
                                            <div className="error error-red">
                                              {errorMessage}
                                            </div>
                                          )}
                                          <label
                                            htmlFor="password"
                                            className={`placeholder ${password
                                              ? "placeholder-pos-aft-focus"
                                              : "placeholder-pos-normal"
                                              }`}
                                          >
                                            Choose Password
                                          </label>
                                          <button
                                            className="show-password"
                                            type="button"
                                            onClick={() =>
                                              setShowPassword(!showPassword)
                                            }
                                          >
                                            {showPassword ? (
                                              <FaRegEye />
                                            ) : (
                                              <FaRegEyeSlash />
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                      <div className="input-group">
                                        <button
                                          type="button"
                                          className="btn-group"
                                          onClick={handleCancel}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          type="submit"
                                          className="btn-group"
                                          onClick={onSubmit}
                                        >
                                          Sign up
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </section>
                            )}
                          </div>
                          {!showCreateAccountField && (
                            <div className="input-group">
                              <button
                                type="button"
                                className="btn-group"
                                onClick={handleCancel}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn-group"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const emailPattern =
                                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                  if (emailPattern.test(gmail)) {
                                    setEmailError("");
                                    if (showPasswordField) {
                                      onLogin(e);
                                    } else {
                                      handleEmailInput(e);
                                    }
                                  } else {
                                    setEmailError("Invalid Email");
                                  }
                                }}
                              >
                                {showPasswordField ? "Login" : "Next"}
                              </button>
                            </div>
                          )}
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </ErrorBoundary>
  );
}

export default App;
