
import React from "react";
import { useState, useEffect,useRef} from "react";

const TypeWriter = (props) => {
  

  const [displayResponse, setDisplayResponse] = useState("");
  const [completedTyping, setCompletedTyping] = useState(false);
  const chatLogContainerRef = useRef(null);

  useEffect(() => {
    // if (!chatHistory?.length) {
    //   return;
    // }

    setCompletedTyping(false);

    let i = 0;
    const stringResponse = props.contents;

    const intervalId = setInterval(() => {
      setDisplayResponse(stringResponse.slice(0, i));

      i++;

      if (i > stringResponse.length) {
        clearInterval(intervalId);
        setCompletedTyping(true);
      }
      // if (chatLogContainerRef.current) {
        chatLogContainerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "end"
        });
        //console.log(displayResponse);
      // }
    }, 20);

    return () => clearInterval(intervalId);
  }, []);

  return <p className="type-writer" ref={chatLogContainerRef}>{displayResponse}</p>;
};



export default TypeWriter;
