import React from "react";
 //{ useEffect, useState }
import ReactDOM from "react-dom/client";
import "./index.css";
import App from './App'

//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

const root = document.getElementById("root");
const DOM = ReactDOM.createRoot(root);

// const MyComponent = () => {
//   // Key 2 ratre.raj@gmail.com
//   // const key ="Bearer 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";
//   const bodyprompt = {
//     prompt: "tallest  building in the world",
//   };
//   const [data, setData] = useState(null);

//   const endpoint = "http://localhost:8080/endpoint/create-prompt";

//   const fetchData = async () => {
//     try {
//       console.log(bodyprompt);
//       const response = await fetch(endpoint, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json"         
//         },
//         body: JSON.stringify(bodyprompt)
//       });
      
//       const json = await response.json();
//       setData(json.data);
//       console.log(json);
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };
//   useEffect(() => {
//     fetchData();
//   }, []);

//   //   const data =  {
//   //     "data": "\n\nYou can use the built-in JavaScript function JSON.parse() to convert a JSON string into a JavaScript object.\n\nExample:\n\nconst jsonString = '{\"name\":\"John\",\"age\":30,\"city\":\"New York\"}';\nconst user = JSON.parse(jsonString);\n\nconsole.log(user);\n// Output: {name: \"John\", age: 30, city: \"New York\"}",
//   //     "message": "Data successfully added.",
//   //     "status": 200
//   // }
//   console.log(data);
//   // const result = JSON.parse(data);

//   return (
//     <div>
//      <h1>{data}</h1>
//       <button onClick={fetchData}>Get advice</button>
//     </div>
//   );
// };

DOM.render(<App />);
