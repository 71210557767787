import React, { useState } from "react";
import "./Disclaime.css";

const DisclaimerPage = ({ onAcknowledged }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleAcknowledge = () => {
    if (isChecked) {
      onAcknowledged();
    }
  };

  return (
    <div className="upper-container">
    <div className="disclaimer-container">
      <h2 className="disc-text">PRIVACY POLICY, TERMS OF USE, AND DISCLAIMER</h2>
      <p>The following document outlines the Privacy Policy, Terms of Use, and Disclaimer that governs your access to and use of the website “BharatKanoon.AI”, application “LawGeek” (collectively, the "Site"), which are provided by BharatKanoon.AI and FIGHTRIGHT Technologies Private Limited ("we", "us", or "our").</p>
      <p>1. PRIVACY POLICY AND TERMS OF USE</p>
            <p>By engaging with our Site and services, you express your understanding and acceptance of the ways we collect, use, and disclose your data as described below:</p>
            <p>User Information: Your provision of Personal Data (including but not limited to your email, name, country, etc.) through our Site or services signifies your agreement to this Privacy Policy and explicit consent to the processing of your Personal Data in accordance with the terms herein.</p>
            <p>Data Collection: We collect a variety of data, including:</p>
            <p>Personal Data: Information such as your name, email address, and country. While providing this information is not mandatory, failure to do so may limit your access to some features of the Site and prevent you from receiving promotional communications from us.</p>
            <p>Automated Information Collection: Our websites automatically collect data like your IP address, browser type, time spent on different pages, etc. If accessed through a mobile device, we might also collect device-specific data. This data is non-personalized and not linked to the Personal Data you choose to share with us.</p>
            <p>Contests, Giveaways, and Surveys Data: During events like contests and giveaways, we may collect additional information like your shipping address and full name.</p>
            <p>Data Use and Disclosure: We primarily collect your data to improve our services. We also use your data to enhance the Site's efficiency and operation, monitor Site usage, and respond to product and customer service requests. We may disclose your data to third parties for business purposes and may share it with third-party service providers, other third parties for analytics, and our partners for marketing purposes.</p>
            <p>Tracking Technologies: Our Site employs cookies and other tracking technologies to enhance user experience. Our use of third-party analytics services, like Google Analytics, allows us to better understand usage patterns. Please note, however, that we are not responsible for the privacy practices or content of any third-party websites linked from/to our Site.</p>
      <p>2. DISCLAIMER</p>
            <p>Before using BharatKanoon.AI and LawGeek, please read this disclaimer carefully:</p>
            <p>General Information Only: BharatKanoon.AI and LawGeek provide general information based on Indian law, not legal advice.</p>
            <p>Jurisdiction Limitation: Information and services from BharatKanoon.AI and LawGeek pertain to Indian law only. </p>
            <p>No Attorney-Client Relationship: Usage of BharatKanoon.AI and LawGeek does not establish an attorney-client relationship with us.</p>
            <p>Accuracy, Completeness, and Timeliness: We strive to provide accurate and timely information, but we do not guarantee the reliability, accuracy, or completeness of the information or services provided by BharatKanoon.AI and LawGeek.</p>
            <p>Reliance on Information: We advise consulting with a legal professional for advice tailored to your circumstances. Any reliance on the information provided by BharatKanoon.AI and LawGeek is strictly at your own risk.</p>
            <p>Governing Law: This disclaimer, and any disputes related to it or the use of BharatKanoon.AI and LawGeek, are governed by the laws of India.</p>
            <p>By accessing and using our Site and services, you acknowledge that you have read, understood, and agreed to the Privacy Policy, Terms of Use, and Disclaimer.</p>
            <p>If you have any queries, please feel free to contact us: contact@fightright.in</p>
      <div className="checkbox-container">
        <label htmlFor="acknowledge-checkbox">
          <input
            type="checkbox"
            id="acknowledge-checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          By using LawGeek, you acknowledge that you have read, understood, and agreed to this PRIVACY POLICY, TERMS OF USE, AND DISCLAIMER.
        </label>
      </div>

      <button className="acknowledge-button" onClick={handleAcknowledge} disabled={!isChecked}>
        Continue to Chat
      </button>
    </div>
    </div>
  );
};

export default DisclaimerPage;
