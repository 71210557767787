import React, { useState } from "react";
import axios from "axios";

function Feedback() {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [comments, setComments] = useState("");

  const onSubmit = async (e) => {
    try {
      const response = await axios.post(
        // "http://localhost:8000/api/user/getUserByEmail",
        "http://localhost:5000/api/v1/feedback/",
        {
          name: fullname,
          email: email,
          mobile: mobile,
          comments: comments,
          created_by: fullname,
        }
      );
      // console.log(response);
      // console.log(response.data);
      if (response.data === 1) {
      } else {
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    console.log("submit");
  };
  return (
    <section>
      <div>
        <div>
          <form>
            <div>
              <div className="input-group">
                <input
                  type="text"
                  value={setFullname}
                  onChange={(e) => setFullname(e.target.value)}
                  required
                  className="input"
                />
                <label
                  htmlFor="full-name"
                  className={`placeholder ${
                    fullname
                      ? "placeholder-pos-aft-focus"
                      : "placeholder-pos-normal"
                  }`}
                >
                  Name
                </label>
                {errorMessage && (
                  <div className="error error-red">{errorMessage}</div>
                )}
              </div>

              <div className="input-group">
                <input
                  id="email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="input"
                />
                {errorMessage && (
                  <div className="error error-red">{errorMessage}</div>
                )}
                <label
                  htmlFor="password"
                  className={`placeholder ${
                    email
                      ? "placeholder-pos-aft-focus"
                      : "placeholder-pos-normal"
                  }`}
                >
                  Email
                </label>
              </div>
              <div className="input-group">
                <input
                  id="mobile"
                  type="text"
                  label="Create password"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  required
                  className="input"
                />
                {errorMessage && (
                  <div className="error error-red">{errorMessage}</div>
                )}
                <label
                  htmlFor="password"
                  className={`placeholder ${
                    mobile
                      ? "placeholder-pos-aft-focus"
                      : "placeholder-pos-normal"
                  }`}
                >
                  Mobile
                </label>
              </div>
              <div className="input-group">
                <textarea
                  id="commment"
                  type="text"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  required
                  className="input"
                />
                {errorMessage && (
                  <div className="error error-red">{errorMessage}</div>
                )}
                <label
                  htmlFor="comment"
                  className={`placeholder ${
                    comments
                      ? "placeholder-pos-aft-focus"
                      : "placeholder-pos-normal"
                  }`}
                >
                  Comments/Feedback
                </label>
              </div>
            </div>
            <div className="input-group">
              {/* <button type="button" className="btn-group" onClick={handleCancel}>
              Cancel
            </button> */}
              <button type="submit" className="btn-group" onClick={onSubmit}>
                submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Feedback;
