import React from "react";
import "./error_page.css";

// function ErrorPage() {
//   return (
//     <div className="container">
//       <div className="content">
//         <h1>Due to high demand, we need to maintain our server.</h1>
//         <h2>We will be back soon.</h2>
//       </div>
//     </div>
//   );
// }

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <div className="container">
            <div className="content">
              <h1>Due to high demand, we need to maintain our server.</h1>
              <h2>We will be back soon.</h2>
            </div>
          </div>
          {/* <h2>Something went wrong.</h2> */}
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
